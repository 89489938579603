<template>
  
  <a-spin :spinning="!cityDataLoaded" >

<section class="nd-layout_container nd-layout_normal">
  <a-breadcrumb class="location-nav">
    <a-breadcrumb-item>酒店预定</a-breadcrumb-item>
    <a-breadcrumb-item>{{this.keywordValue.name}}酒店列表</a-breadcrumb-item>
  </a-breadcrumb>

  <a-row :gutter="[0,8]">
    <hotelSearchBox v-if="cityDataLoaded" type="list" :propsParams="urlParams" :propsKeyword="keywordValue"  @updateHotelList="fetchHotelList" @updateKeywordValue="updateKeywordValue"/>
    <div class="top-filter-container ant-primary-box">

        
        <dl class="position-filter" v-if="districts.length>0">
          <dt>区域位置</dt>
          <dd>
            <div style="width: 100%;">
         
              <a-space :size="[4, 8]" wrap>
                <a-checkable-tag
                  v-for="(item, index) in districts"
                  :key="index"
                  v-model:checked="selectTags.districts[item.DistrictCode]"
                  @change="handleFliterChange('district')"
                >
                  {{ item.DistrictName }}
                </a-checkable-tag>
              </a-space>
                        
            
            
            </div>
          </dd>

        </dl>

        <dl class="star-filter">
          <dt>酒店星际</dt>
          <dd>
            
            <a-space :size="[4, 8]" >
              <a-checkable-tag
                v-for="(item, index) in starlevel"
                :key="index"
                v-model:checked="selectTags.starlevel[item.val]"
                @change="handleFliterChange('star')"
              >
                {{ item.label }}
              </a-checkable-tag>
            </a-space>


          </dd>

        </dl>

        <dl class="price-filter">
          <dt>酒店价格</dt>
          <dd>
            
            <a-space :size="[4, 8]" >
              <a-checkable-tag
                v-for="(item, index) in priceRange"
                :key="index"
                v-model:checked="selectTags.priceRange[item.val]"
                @change="checked => handlePriceChange(item, checked)"
              >
                {{ item.label }}
              </a-checkable-tag>
              <span>自定义:</span>
              <a-input size="small" v-model:value="priceRangeCustom.MinPrice" style="width: 80px;"  @keydown="onPriceRangeCustomChange('min',$event)" @keyup="onPriceRangeCustomChange('min',$event)" > <template #prefix> ¥ </template> </a-input>
              -
              <a-input size="small" v-model:value="priceRangeCustom.MaxPrice" style="width: 80px;"  @keydown="onPriceRangeCustomChange('max',$event)" @keyup="onPriceRangeCustomChange('max',$event)"> <template #prefix> ¥ </template> </a-input>
              <a-button type="link" v-if="priceRangeCustom.MinPrice!=''" danger @click="onCustomerPriceClick">确定</a-button>
            </a-space>
            

          </dd>

        </dl>

   

      
    
    </div>
    <div  class="top-filter-tag">
 
        
        <a-space :size="[0, 8]" wrap >
          <a-tag
            closable 
            color="green"
            v-for="(districtId, index) in condition.DistrictCode"
            @close="handleTabClose('district',districtId)"
            :key="index"
          >
           {{districts.find(d=>d.DistrictCode==districtId).DistrictName }}
          </a-tag>

          <a-tag
            closable 
            color="green"
            v-for="(starVal, index) in condition.Star"
            @close="handleTabClose('star',starVal)"
            :key="index"
          >
           {{starlevel.find(d=>d.val==starVal).label }}
          </a-tag>

          <a-tag
           
            closable 
            color="green"
            v-if="condition.MinPrice!=''"    
            @close="handleTabClose('price',condition.MinPrice+'-'+condition.MaxPrice)"    
          >
           {{condition.MinPrice }} {{condition.MaxPrice>0?`-`+condition.MaxPrice:`以上` }}
          </a-tag>
          


          <a @click="clearFliter()" v-if="condition.DistrictCode.length>0 ||condition.Star.length>0 || condition.MinPrice!='' "><ClearOutlined /></a>
        </a-space>

   
    </div>

  </a-row>
  
  <a-row :gutter="[8,20]">
    <a-col :span="17">
        <div class="hotel-sort  nd-flex  ant-primary-box"> 
          <ul class="hotel-sort_list">
            <li v-for="(option, index) in sortOptions"
              :key="index"
              :class="{ active: option.isActive }"
              @click="handleSort(option)"
            >
              <span>{{ option.label }}</span>
            </li>

            
          </ul>
          <div class="hotel-sort_result nd-flex"> <span>找到 <strong>{{total}}</strong> 家酒店</span><a-pagination size="small"  v-model:current="page" :total="total" simple @change="handlePageChange" /></div>
        </div>
        
        <div class="hotel-list-container">

          <!--加载前骨架-->
          <ul class="hotel-list-group"  v-if="hotelLoading ">
            <li class="hotel-list-group_item">
              <a-card>
                <div class="hotel-head nd-flex" style="justify-content: space-between;align-items: center;">             
                  <a-skeleton-input style="width: 400px " :block="true"  :active="true" size="small" />
                  <a-skeleton-input  :active="true"  size="small"/>        
                </div>
               
                <a-card-meta>
                  <template #avatar>
                    <a-skeleton-image  :active="true"/>
                  </template>
                  <template #description>
                    <a-skeleton :paragraph="{ rows: 1 }" :active="true"/>

                    <div class="hotel-card-price">
                      <a-row type="flex" justify="space-between" align="middle">
                        <a-skeleton-button   :active="true" />
                        <a-skeleton-button   :active="true"/>
                      
                      </a-row>
                    </div>
                    
                  </template>
                </a-card-meta>

              </a-card>
              
            </li>
          </ul>


          <a-empty :description="errorMessage" v-if="!hotelLoading && hotels.length==0" />
          <!--加载后酒店列表-->
          <ul class="hotel-list-group"  v-if="!hotelLoading&& hotels.length>0">
            <li class="hotel-list-group_item"  v-for="(hotel, index) in  this.hotels"
              :key="index"
              @mouseenter="highlightMarker(index)"
              @mouseleave="resetMarker(index)"
            >
              <a-card hoverable>
          
                <div class="hotel-head nd-flex" style="justify-content: space-between;align-items: center;">             
                    <a><div class="hotel-map_marker">{{ index+1 }}</div><strong>{{hotel.HotelName}}</strong> {{ hotel.HotelEnName }}</a>
                    <a-rate :value="hotel.Star" style="font-size: 12px"  disabled  />               
                </div>
        
                <a-card-meta>
                  <template #avatar>
                    <a-image
                      class="hotel-card-img"
                      :width="160"
                      :height="142"
                      :preview="false"
                      :src="hotel.HotelMainPhoto"
                      fallback="https://img.etxing.com/b2b/images/hotelDefault.svg"
                    />
                  </template>
                  <template #description>
                    <div class="hotel-card-addr"><a-typography-text>{{hotel.Address}}</a-typography-text></div>
                    <div class="hotel-card-desc">
                      <a-typography-paragraph :ellipsis="{ rows: 3 }" type="secondary"  
                      :content="hotel.Summary"
                      />
                    </div>
                    <div class="hotel-card-price">
                      <a-row type="flex" justify="space-between" align="middle">
                        <span><sub>CNY</sub> <strong>{{ hotel.LowestPrice }}</strong> <sub>起</sub></span>
                        <a-button  type="primary" @click="gotoHotelDetail(hotel.HotelId)">查看详情</a-button >
                      
                      </a-row>
                    </div>
                    
                  </template>
                </a-card-meta>
              </a-card>

            </li>
          </ul>



        </div>
        <div class="hotel-list-pagination">
          <a-pagination
            v-model:current="page"

            :showQuickJumper="true"
            :showSizeChanger="false"
            :total="total"
            @change="handlePageChange"
            :show-total="total => `共 ${total} 家酒店`"
          />
        </div>

    </a-col>
    <a-col :span="7">
      <a-affix :offset-top="0" >
      <div id="map" style="height: 600px;"></div>
      </a-affix>
    </a-col>


  </a-row>
  
</section>

</a-spin>

</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';
import { ClearOutlined} from '@ant-design/icons-vue';
import '@/assets/styles/hotel.css'
import hotelSearchBox from '@/components/hotelSearchBox.vue';
import router from "@/assets/router";
import { message } from 'ant-design-vue';
import dayjs from 'dayjs';
export default {
  name: 'HotelList', 
  data(){
    return{
      sortOptions: [
        {
          id: 'hot',
          label: '热销优先',
          isActive: true,

        },
        {
          id: 'price',
          label: '价格(高→低)',
          isActive: false,

        },
        {
          id: 'priceLowToHigh',
          label: '价格(低→高)',
          isActive: false,

        },
        {
          id: 'star',
          label: '星级(高→低)',
          isActive: false,

        },
        {
          id: 'starLowToHigh',
          label: '星级(低→高)',
          isActive: false,
        }
      ],
      districts:[],
                
      starlevel:[{val:2,label:"经济型/青旅"},{val:3,label:"3星级/舒适"},{val:4,label:"4星级/高档"},{val:5,label:"5星级/豪华"}],
      priceRange:[{val:"0-200",label:"200以下"},{val:"200-500",label:"200-500"},{val:"500-800",label:"500-800"},{val:"800-1200",label:"800-1200"},{val:"1200-",label:"1200以上"}],
      selectTags: {
        starlevel: [],
        priceRange: [],
        districts: []
      },
      MinPrice:"",
      priceRangeCustom:{
        MinPrice:"",
        MaxPrice:""
      },
      defaultCheckDates: [dayjs().add(1, 'day'), dayjs().add(2, 'day')],
      filter:"",
      AMap: null, //地图对象
      map: null,
      hotelLoading: false,   //用于标记酒店列表是否加载中
      cityDataLoaded: false, // 用于标记城市数据是否已加载
      errorMessage:"",
      hotels: [],
      hotelMasker:[],
      keywordValue:{},
      condition:{
        CityCode: 0,
        CheckIn:"",
        CheckOut: "",
        DistrictCode: [],
        Star:[],
        MinPrice: "",
        MaxPrice: "",
        OrderBy: "",
        RoomCount: 1,
        AdultCount:2,
        ChildCount: 0,
        ChildAgeList:"",
        PageIndex: 0,
      },
      page: 1, // 当前页
      pageSize: 10, // 每页显示的条数
      total: 0, // 总条数
     
    }
    
  },
  watch: {
    /*
    keywordValue(newVal,oldVal) {
      console.log('Keyword Value1 :', oldVal);
      console.log('Keyword Value updated1:', newVal);
      if(newVal.name!=newVal.name){
        console.log("变化1")
      }
    }
    */
  },
  computed: {
    urlParams() {
      const query = this.$route.query;
      if(!query.checkInDate) query.checkInDate=dayjs().add(1, 'day').format('YYYY-MM-DD')
      if(!query.checkOutDate) query.checkOutDate=dayjs().add(2, 'day').format('YYYY-MM-DD')
      if(!query.roomCount) query.roomCount=1
      if(!query.adultCount) query.adultCount=2
      if(!query.childCount) query.childCount=0
      return  query;
    }
  },
  created(){ 


    this.fetchCityInfo()
    this.fetchHotelList()
  },
  mounted() {
    
    document.title = this.$route.meta.title;

  },
  beforeUnmount() {
    //window.removeEventListener('scroll', this.handleScroll);
  },
  components: {
    hotelSearchBox,
    ClearOutlined
  },

  methods: {
    gotoHotelDetail(hotelId){
      const queryPara={     
                        hotelId: hotelId,       
                        checkInDate: this.condition.CheckIn,
                        checkOutDate: this.condition.CheckOut,
                        roomCount: this.condition.RoomCount,             
                        adultCount: this.condition.AdultCount,
                        childCount: this.condition.ChildCount
                      }
      if(this.condition.ChildCount>0)
        queryPara.childAgeList =this.condition.ChildAgeList;

        console.log(queryPara)
      //  return
        router.push({
            name: 'HotelDetail',
            query: queryPara
        });
    },
    updateKeywordValue(value){
      //console.log("updateKeywordValue",value)
      //this.urlParams.cityId=value.id
      if(value.type=="city"){
        this.fetchCityInfo(value.id)

      }

      
     
    },

   fetchCityInfo(cityId) {     

    this.$axios.post('/city',{
         "CityCode": cityId??this.urlParams.cityId
       })
      .then(response =>{
      
        if(response.data.RetCode=="Fail")  {
            message.error(response.data.RetMessage,1.5,()=>{ router.push('/') })
          
          }else{
            this.districts=response.data.City.Districts
            this.keywordValue={
              type:"city",
              id:parseInt(this.urlParams.cityId),
              name:response.data.City.CityName
            }


            this.cityDataLoaded = true;
            //console.log(this.keywordValue)
          }

      })
      .catch(error => {
        message.error(error.data.RetMessage,1.5,()=>{ router.push('/') })
        console.error("Error fetching city data:", error);
      });

     },
    
   
    async fetchHotelList(params) {    
      
      var queryParams=this.urlParams
      if(params)   //params 从组件传过来的参数
        queryParams=params

      //console.log("传过来的queryParams:",params)
      this.hotels=[]
      this.hotelLoading = true;   

      this.condition.CityCode=queryParams.cityId
      this.condition.CheckIn=queryParams.checkInDate
      this.condition.CheckOut=queryParams.checkOutDate
      this.condition.RoomCount=queryParams.roomCount
      this.condition.AdultCount=queryParams.adultCount
      this.condition.ChildCount=queryParams.childCount
      if(queryParams.childCount>0)
        this.condition.ChildAgeList =queryParams.childAgeList
      //从组件传过来
      if(params) {
        this.clearFliter(false)  //清空筛选条件 不重新刷新酒店列表     
      }
      
       try {   
         const response = await this.$axios.post('/b2bHotels',this.condition);
          if(response.data.RetCode=="Fail")  {
            this.errorMessage=response.data.RetMessage
          }else{
              this.hotels=response.data.HotelLowestPrices
              this.page=response.data.CurrentPage+1
              this.pageSize=response.data.PageSize
              this.total=response.data.Total
              this.hotelMasker=[]
              this.initAMap();
          }

              //console.log(response.data)
       } catch (error) {
         console.error('获取数据失败:', error);
       } finally {
         this.hotelLoading = false;
      }

     },
     

    initAMap() {
      window._AMapSecurityConfig = {
        securityJsCode: "838bb10db18fd3270685b3ae635e82d7",
      };
      AMapLoader.load({
        key: "708f333a81886d917bcfe22779aa0087", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Scale","AMap.ToolBar", "AMap.InfoWindow"], //需要使用的的插件列表，如比例尺'AMap.Scale'，支持添加多个如：['...','...']

      })
        .then((AMap) => {
          this.AMap = AMap;  // 存储 AMap 对象
          this.map = new AMap.Map("map", {
            // 设置地图容器id
            viewMode: "2D", //设置地图模式,
            zoom: 13, // 初始化地图级别
            center: [this.hotels[0].Longitude,this.hotels[0].Latitude], // 初始化地图中心点位置
            liteStyle: true // 启用简化样式
          });

          this.map.addControl(new AMap.ToolBar()); // 添加缩放控件

          this.hotels.forEach((hotel, index) => {
            //console.log(hotel)
            const markerContent = document.createElement('div');
            markerContent.innerHTML = `<div class="hotel-map_marker">${index + 1}</div>`;
            const marker= new AMap.Marker({
              map: this.map,
              position: [hotel.Longitude,hotel.Latitude],
              title: hotel.HotelName,
              content: markerContent,
              offset: new AMap.Pixel(-15, -15) // 调整标记偏移
            });
            
            marker.on('mouseover', () => {
              this.highlightMarker(index,false);

              var content = [ `<div class="hotel-map_windows-info">${hotel.HotelName}</div>`,];

              this.infoWindow = new AMap.InfoWindow({
                isCustom: true, //使用自定义窗体
                content: content.join("<br>"),
                offset: new AMap.Pixel(16, -45),
              });

              this.infoWindow.open(this.map, marker.getPosition());
            });

            marker.on('mouseout', () => {
              this.resetMarker(index);
              this.infoWindow.close();
            });
            marker.setMap(this.map);
           this.hotelMasker.push(marker);
           //console.log(this.hotelMasker)
          });

          this.infoWindow = new AMap.InfoWindow({
            offset: new AMap.Pixel(0, -30)
          });



        })
        .catch((e) => {
          console.log(e);
        });
    },


    highlightMarker(index,setCenter=true) {
      //console.log(this.hotelMasker[index])
      if (this.hotelMasker[index]) {
        const markerContent = this.hotelMasker[index].getContent().firstChild;
        markerContent.classList.add('hover'); 
        if(setCenter)
          this.map.panTo( [this.hotels[index].Longitude,this.hotels[index].Latitude]); // 设置地图中心

      }
    },
    resetMarker(index) {
      if (this.hotelMasker[index]) {
        const markerContent = this.hotelMasker[index].getContent().firstChild;
        markerContent.classList.remove('hover');
      }
    },

    //分页按钮
    handlePageChange(page) {
      this.condition.PageIndex=page-1
      this.fetchHotelList()
      this.page = page;
      
    },
    //自定义价格强制输入数字
    onPriceRangeCustomChange: function (type,event) {
      if (type=="min" && isNaN(event.target.value)) 
          this.priceRangeCustom.MinPrice=""
 
      if (type=="max" && isNaN(event.target.value)) 
          this.priceRangeCustom.MaxPrice=""

      //this.fetchHotelList()
    },
    //排序
    handleSort(sort){
      this.sortOptions.forEach(option => option.isActive = false);
      this.sortOptions.find(option => option.id ===sort.id).isActive = true;
      switch(sort.id){
        case 'price':
          this.condition.OrderBy=2
          break;
        case 'priceLowToHigh':
          this.condition.OrderBy=1
          break;

          case 'star':
          this.condition.OrderBy=4
          break;
          
          case 'starLowToHigh':
          this.condition.OrderBy=3
          break;
          default:
            this.condition.OrderBy=""
      }

      this.fetchHotelList()
  

    },
    //自定义价格确认
    onCustomerPriceClick: function () {
      this.selectTags.priceRange = {};
      if(this.priceRangeCustom.MinPrice=="") this.priceRangeCustom.MinPrice=0
      
      // 检查MinPrice是否大于MaxPrice
      if (parseInt(this.priceRangeCustom.MinPrice) > parseInt(this.priceRangeCustom.MaxPrice)  && this.priceRangeCustom.MaxPrice !== "") {   
        // 如果是，交换两个值
        [this.priceRangeCustom.MinPrice, this.priceRangeCustom.MaxPrice] = [this.priceRangeCustom.MaxPrice, this.priceRangeCustom.MinPrice];
      }
      //console.log(this.priceRangeCustom)
      const priceKey=this.priceRangeCustom.MinPrice+'-'+this.priceRangeCustom.MaxPrice // 将两个值拼接成一个字符串作为键设置筛选标签
      this.selectTags.priceRange[priceKey]= true;
      this.condition.MinPrice=this.priceRangeCustom.MinPrice
      this.condition.MaxPrice=this.priceRangeCustom.MaxPrice
      this.condition.PageIndex=0 //页数默认为0即为第一页开始
      this.fetchHotelList()
    },
    //星级和区域筛选
    handleFliterChange(type) {
      console.log(this.selectTags.districts);
      switch (type) {
        case 'star':
          this.condition.Star= Object.keys(this.selectTags.starlevel)
                        .filter(key => this.selectTags.starlevel[key] === true)
                        .map(key => parseInt(key, 10)); // 将字符串键转换为整数
          break;
        case 'district':
          
          this.condition.DistrictCode= Object.keys(this.selectTags.districts)
                        .filter(key => this.selectTags.districts[key] === true)
                        .map(key => parseInt(key, 10)); // 将字符串键转换为整数
         
      }
      this.condition.PageIndex=0 //页数默认为0即为第一页开始
      this.fetchHotelList()


    },   
    //价格过滤
    handlePriceChange(tag, checked) {
      //清除自定义价格筛选值
      this.priceRangeCustom.MinPrice=""
      this.priceRangeCustom.MaxPrice=""

      this.selectTags.priceRange = {};
      this.selectTags.priceRange[tag.val]= checked;
      //console.log(this.selectTags.priceRange)
      this.condition.MinPrice=tag.val.split("-")[0]
      this.condition.MaxPrice=tag.val.split("-")[1]
      this.condition.PageIndex=0 //页数默认为0即为第一页开始
      this.fetchHotelList()
      //console.log(tag, checked);
    },
    //筛选标签关闭事件
    handleTabClose(type,value){

      switch (type) {
        case 'price':
            this.selectTags.priceRange = {};
            this.priceRangeCustom.MinPrice=""
            this.priceRangeCustom.MaxPrice=""
            this.condition.MinPrice=""
            this.condition.MaxPrice=""
          break;
        case 'star':
          this.condition.Star= this.condition.Star.filter(item => item !== value);
          this.selectTags.starlevel[value] = false;
          break;
        case 'district':
          this.condition.DistrictCode= this.condition.DistrictCode.filter(item => item !== value);
          this.selectTags.districts[value] = false;
          break;
         
      }
      this.condition.PageIndex=0 //页数默认为0即为第一页开始
      this.fetchHotelList()

    },
    //清除所有筛选条件
    clearFliter(isReload=true){
      //重置排序
      this.sortOptions.forEach(option => option.isActive = false);
      this.sortOptions.find(option => option.id ==='hot').isActive = true;
      //清除筛选标签
      this.selectTags= {
        starlevel: [],
        priceRange: [],
        districts: []
      }
      this.priceRangeCustom.MinPrice=""
      this.priceRangeCustom.MaxPrice=""
      //清除筛选条件
      this.condition.DistrictCode=[]
      this.condition.Star=[]
      this.condition.MinPrice=""
      this.condition.MaxPrice=""
      this.condition.PageIndex=0 //页数默认为0即为第一页开始
      if(isReload) this.fetchHotelList()
    }

    

  }
}
</script>
<style>


</style>