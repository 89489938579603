import axios from 'axios';
import Cookies from 'js-cookie';
const instance = axios.create({
    baseURL: '/api',// API 基础 URL
  timeout: 10000, // 请求超时时间
  headers: { 'Content-Type': 'application/json' }
});

// 添加请求拦截器
instance.interceptors.request.use(config => {
    const token = Cookies.get('token');
    //console.log("token:"+token)
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;                                   
  }
  return config;
}, error => {
  return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(response => {
  return response;
}, error => {
  return Promise.reject(error);
});

export default instance;
