<template>

  <a-layout class="nd-layout_container nd-layout_large">

    <a-layout-sider width="200" theme="light"><leftMenu /></a-layout-sider>

    <a-layout-content :style="{ background: '#fff', padding: '24px', margin: 0, minHeight: '500px' }" >
            
      <a-form>
        <a-form-item >
          <a-radio-group v-model:value="formData.dateType" @change="handleDateTypeChange">
            <a-radio value="0">所有日期</a-radio>
            <a-radio value="1">入住日期</a-radio>
            <a-radio value="2">离店日期</a-radio>
            <a-radio value="3">预定日期</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-divider />

        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="选择日期" >
              <a-range-picker style="width: 100%" :disabled="formData.dateType=='0'"  v-model:value="formData.dateValue"  :disabledDate="disabledDate"  />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="订单编号">
              <a-input v-model:value="formData.orderId" placeholder="输入贵司单号/订单号"></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="酒店名称" >
              <a-input v-model:value="formData.hotel" placeholder="酒店名称"></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="入住人" >
              <a-input v-model:value="formData.guest" placeholder="入住人"></a-input>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item label="订单状态">
              <a-select v-model:value="formData.orderstatu" placeholder="选择订单状态">
                <a-select-option value="已确认">已确认</a-select-option>
                <a-select-option value="已取消">已取消</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item label="支付状态">
              <a-select v-model:value="formData.paymentstatu" placeholder="选择订单状态">
                <a-select-option value="已确认">已确认</a-select-option>
                <a-select-option value="已取消">已取消</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="支付方式">
              <a-select v-model:value="formData.paymenttype" placeholder="选择订单状态">
                <a-select-option value="已确认">已确认</a-select-option>
                <a-select-option value="已取消">已取消</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-button type="primary" :disabled="searchBtnLoading" :loading="searchBtnLoading" @click="getOrderList(1)">搜索</a-button>
          </a-col>
        </a-row>




      </a-form>
      <div class="search-result-list">
        
        <a-table 
        class="ant-table-striped"
        :rowClassName="setRowClass"
        :columns="columns" 
        :pagination="false" 
        :data-source="dataSource" 
        bordered 
        @resizeColumn="handleResizeColumn">
          <template #bodyCell="{ column, record }">
            <template v-if="column.dataIndex === 'orderIdDetail'">
              <a>{{ record.orderId }}</a>
              <p><a-tag  :bordered="false" :color="record.orderPaymentStatu=='未支付'?'red':'green'">{{ record.orderPaymentStatu }}  </a-tag></p>
              <p>{{ record.hotelName }}</p>
            </template>
            <template v-if="column.dataIndex === 'checkInOutDate'">    
              <p>{{ record.checkIn }}</p>   
              <p>{{ record.checkOut }}</p>
            </template>
            <template v-if="column.dataIndex === 'orderStatu'"> 
              <a-tag  :bordered="false" :color="record.orderStatu=='已确认'?'success':'warning'">{{ record.orderStatu }}  </a-tag>   
            </template>
            <template v-if="column.dataIndex === 'operation'">    
              <a-button type="text" :href="`/order/detail?orderId=${record.orderId}`" >查看订单</a-button>
              <a-button type="text" danger :href="`/hotel/Order/Payment?OrderId=${record.orderId}`">继续支付</a-button>
            </template>


          </template>
        </a-table>



        <div class="hotel-list-pagination">
          <a-pagination
            v-model:current="formData.page"

            :showQuickJumper="true"
            :showSizeChanger="false"
            :total="formData.count"
            @change="handlePageChange"
            :show-total="total => `共 ${total} 条订单`"
          />
        </div>


      </div>

    </a-layout-content>

  </a-layout>


</template>



<script>
import leftMenu from '@/components/leftMenu.vue';
import dayjs from 'dayjs';
//import router from '@/assets/router';
export default {
  name: 'OrderList', 
  data(){
    return{
      searchBtnLoading:false,
      formData:{
        dateType:"0",
        dateValue: [dayjs().add(0, 'day'), dayjs().add(1, 'day')],
        orderId:"",
        hotel:"",
        guest:"",
        orderstatu:"",
        paymentstatu:"",
        paymenttype:"",
        page:1,
        pageSize:10,
        count:0,
      },

     columns:[
              {
                title: '序号',
                width: 70,
                dataIndex: 'orderIndex',
             
              },
              {
                title: '订单号/产品明细',
                dataIndex: 'orderIdDetail',
        
              },
              {
                title: '订单金额',
                resizable: true,
                width: 100,
                dataIndex: 'totalAmount',               
              },
              {
                title: '房型',
                dataIndex: 'roomRateName',
              
              },

              {
                title: '入/离日期',
                dataIndex: 'checkInOutDate',
               
              },

              {
                title: '间夜',
                dataIndex: 'nightRoom',
                
              },
              {
                title: '入住人',
                dataIndex: 'guest',
                
              },
              {
                title: '订单状态',
                dataIndex: 'orderStatu',
                
              },
              {
                title: '下单时间',
                dataIndex: 'orderTime',
                
              },
              
              {
                title: '操作',
                dataIndex: 'operation',
              },
            ],
            dataSource:[]


    }
    
  },
  mounted() {
    document.title = this.$route.meta.title;
// 假设这是在组件的生命周期钩子或者某个事件处理器中

  },
  created() { 
    this.orderId=this.$route.query.OrderId;
    this.getOrderList()
  },
  components: { 
    leftMenu
  },
  computed: {


  }, 


  methods: {
    disabledDate(current) {
      if (this.formData.dateType === '3') 
        return current && current.isAfter(dayjs().startOf('day'));        
    },
    async getOrderList(page){

        this.searchBtnLoading = true;
        this.formData.page=page??this.formData.page  //点击搜索按钮需要重置第一页，
        const [startDate, endDate] = this.formData.dateValue;
        var data ={         
          OrderId: this.formData.orderId.startsWith("CQ")?this.formData.orderId:"",
          BuyerOrderId:this.formData.orderId.startsWith("CQ")?"":this.formData.orderId,
          HotelName: this.formData.hotel,
          GuestName: this.formData.guest,
          Booker: "",
          PayType: this.formData.paymenttype,
          PayStatus: this.formData.paymentstatu,
          Status: this.formData.orderstatu,
          CheckInStart: this.formData.dateType=="1"?startDate.format('YYYY-MM-DD'):"",
          CheckInEnd: this.formData.dateType=="1"?endDate.format('YYYY-MM-DD'):"",
          CheckOutStart:this.formData.dateType=="2"?startDate.format('YYYY-MM-DD'):"",
          CheckOutEnd: this.formData.dateType=="2"?endDate.format('YYYY-MM-DD'):"",
          StartTime:this.formData.dateType=="3"?startDate.format('YYYY-MM-DD'):"",
          EndTime: this.formData.dateType=="3"?endDate.format('YYYY-MM-DD'):"",
          PageIndex:this.formData.page-1,
          PageSize: this.formData.pageSize,
        }
        try {   
        const response = await this.$axios.post('/b2bBookingList',data);
        console.log(response.data)
        if (response.data.RetCode === "Success") {
            this.formData.count=response.data.count;
            this.dataSource=response.data.data.map((item, index) => {
              return {
                orderIndex: index+1,
                orderId: item.OrderId,
                hotelName: item.HotelName,
                totalAmount: item.TotalAmount,
                roomRateName: item.RoomName,
                checkIn:item.CheckIn,
                checkOut:item.CheckOut,
                nightRoom:item.RoomCount,
                guest: item.Guests[0],
                orderStatu:item.OrderStatusName,
                orderPaymentStatu:item.PayStatusName,
                orderTime: item.CreateDate
              };
            });
        }


      } catch (error) {

        this.$error({
                title: '啊哦~',
                content: '获取数据失败',
                okType: 'danger',
                okText: '请刷新重试',
                onOk() {                 
                    setTimeout(() => {
                      window.location.reload();
                    }, 100);  
                },
        });

      }finally {
                this.searchBtnLoading = false;
      }



    },
    //日期类型切换重置日期
    handleDateTypeChange(){
      if (this.formData.dateType === '3') {
        const currentDate = dayjs().startOf('day');
        const [startDate, endDate] = this.formData.dateValue;
       //console.log(startDate.isAfter(currentDate) )
        //console.log(startDate,endDate)
        // 检查 startDate 和 endDate 是否都在有效日期范围内
        if (!startDate || !endDate || startDate.isAfter(currentDate) || endDate.isAfter(currentDate)) {
          this.formData.dateValue = [currentDate.add(-7, 'day'), currentDate];
        }
      }
    },
    //分页按钮
    handlePageChange(page) {
      this.formData.page=page
      this.getOrderList()
      
    },
    setRowClass(record) {
      // 根据你的逻辑，判断是否要将行设为灰色
      // 例如，假设订单支付状态为 '未支付' 的订单行显示为灰色
      if (record.orderStatu === '已取消') {
        return 'table-striped';  // 返回类名
      }
      return ''; // 默认不返回任何类
    },
    handleResizeColumn(w, col){
      col.width = w;
    }
  }
}
</script>
<style scoped>
.ant-table-striped :deep(.table-striped) td {
  background-color: #fafafa;
  color:#ccc
}

</style>
