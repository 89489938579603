<template>



  <div class="nd-layout_container nd-layout_normal">
    <a-steps
      type="navigation"
      :current=1
      size="small"
      :items="[
        {
          title: '填写订单',
          status: 'finish',

        },
        {
          title: '选择支付方式',
          status: 'process',
        },
        {
          title: '完成',
          status: 'wait',

        },
      ]"
    ></a-steps>   






  <div class="nd-panel-body">

    <a-alert  type="warning" show-icon>
      <template #message>   
        <a-statistic-countdown
          :value="deadline"
          format="m分s秒"
          :valueStyle="{'fontSize':'13px','color':'#ff4d4f'}"
        >        
          <template #prefix>
            <span style="color:#1a1b1a;">请在</span>

          </template>
          <template #suffix>
            <span style="color:#1a1b1a;">内支付，否则会被自动取消订单</span>
          </template>
      
        </a-statistic-countdown> 
      
      </template>
    </a-alert>
    <div  class="bookingInfo">
          <a-divider orientation="left" orientation-margin="0px" style="font-weight: 700;">订单详情</a-divider>


          <a-form-item label="酒店名称">
            <span>{{orderData.HotelName}}</span>
          </a-form-item>

          <a-form-item label="房间类型">
            <span>{{orderData.RatePlans.RoomName}} {{ orderData.RatePlans.RatePlanName }}</span>
          </a-form-item>

          <a-form-item label="间 / 夜数">
            <span>房间：{{orderData.RatePlans.RoomCount}} 间 / 夜数：{{orderData.RatePlans.NightCount}} 晚 (应付价格 = 入住总和 x 房间数)</span>
          </a-form-item>


          <a-form-item label="入住日期">
           
            <a-tooltip v-for="(item,index) in orderData.RatePlans.RatePlanDetails" :key="index"  color="#87d068">
              <template #title>
                <span class="room_addition">
                    <span>成早 {{ item.BreakfastCount }}</span>
                    <span>童早 {{ item.BreakfastChildCount }}</span>
                    <span>加床 {{ item.BedCount }}</span>
                </span>
              </template>
              <span class="dateStyle">{{ item.PriceDate }} / 
                <span style="color:#f80;">
                {{ item.BreakfastSale*item.BreakfastCount+item.BreakfastChildSale*item.BreakfastChildCount+item.BedPriceSale*item.BedCount+ item.RoomPriceSale }}
                </span>
              </span>
            </a-tooltip>
                    
          </a-form-item>


          <a-form-item label="客人信息">
            <span>{{orderData.RatePlans.Guests.map(guest => guest.FirstName + " " + guest.LastName).join(", ")}}</span>
          </a-form-item>
          <a-form-item label="备注信息">
            <span>{{ orderData.Remark }}</span>
          </a-form-item>
    </div>
    <a-divider orientation="left" orientation-margin="0px" style="font-weight: 700;">支付平台</a-divider>
    <div class="paymentInfo">
      <div>

        <a-statistic title="应付金额 CNY " :value="orderData.TotalAmount"  :value-style="{ color: '#f80' }">
          <template #prefix>
            ¥
          </template>
        </a-statistic>



        
      </div>

      <a-tabs v-model:activeKey="payData.PaymentType">
        <a-tab-pane key="1" v-if="memberData.MemberCredit.BalancePeriod!=4" >
          <template #tab>
            <!-- 自定义tab内容 -->
            <span> <PayCircleOutlined />信誉额支付</span>
           
          </template>
          <!-- Tab Pane 1 的内容 -->
          <div>
            <a-form-item label="支付密码">
              <a-input-password
                placeholder="请输入支付密码"
                v-model:value="payData.payPassword"
              />
            </a-form-item>

          </div>
        </a-tab-pane>
        <a-tab-pane key="3" force-render>
          <template #tab>
            <!-- 自定义tab内容 -->
            <span><AlipayOutlined />支付宝</span>
          
          </template>
          <!-- Tab Pane 2 的内容 -->
        
        </a-tab-pane>
        <a-tab-pane key="4" >
          <template #tab>
            <!-- 自定义tab内容 -->
            <span><WechatOutlined />微信</span>
            
          </template>
          <!-- Tab Pane 3 的内容 -->
         
        </a-tab-pane>
      </a-tabs>

      <div class="payBox">
        <a-button :disabled="paybtnLoading" :loading="paybtnLoading" type="primary"  @click="gopay">去支付</a-button>

      </div>
    </div>


  </div>

  </div>



</template>



<script>
 import Cookies from 'js-cookie';
import {PayCircleOutlined,AlipayOutlined,WechatOutlined} from '@ant-design/icons-vue';
//import { Modal } from 'ant-design-vue';
//import { message } from 'ant-design-vue';
import router from '@/assets/router';
import dayjs from 'dayjs';
export default {
name: 'HotelOrder', 
data(){
  return{
    deadline: dayjs().valueOf(),
    hotelId:0,
    orderData:{
      RatePlans:{
        Guests:[]}
    },
    memberData:{},
    //支付参数
    payData:{
      PaymentType:"1",
      HotelId:"",
      OrderId:"",
      payPassword:""
    },
    
    paybtnLoading:false

  }
  
},
mounted() {
  document.title = this.$route.meta.title;

},
created() {

 
  if(Cookies.get('etxing_'+Cookies.get('token'))){
    this.memberData=JSON.parse(Cookies.get('etxing_'+Cookies.get('token')));
    if(this.memberData.MemberCredit.BalancePeriod==4) this.payData.PaymentType="3"
  }
  console.log(this.memberData.MemberCredit.BalancePeriod); 
  this.getOrderDetail()
},
components: { 
  PayCircleOutlined,
  AlipayOutlined,
  WechatOutlined
},
computed: {


  },


methods: {
  //获取订单信息
  async getOrderDetail() {  

    try {   
      const response = await this.$axios.get('/bookingDetail/'+this.$route.query.OrderId );
    
      if (response.data.RetCode === "Success") {
            this.hotelId=response.data.BookingDetail.HotelId
            this.orderData=response.data.BookingDetail
            this.orderData.RatePlans=response.data.BookingDetail.RatePlans[0]
            this.orderData.RatePlans.Guests=response.data.BookingDetail.RatePlans.Guests

            const timestamp = response.data.BookingDetail.CreateTime.match(/\d+/)[0];
            this.deadline=dayjs(parseInt(timestamp)).add(10,'minute').valueOf(); //倒计时10分钟
            console.log(response.data.BookingDetail.RatePlans)
   
      }else{
       
        this.$error({
              title: '啊哦~',
              content: '很抱歉，该房型已经预定完了，换个房型试试吧！',
              okType: 'danger',
              okText: '返回重新选择',
              onOk() {
                  router.go(-1) 
                  setTimeout(() => {
                    window.location.reload();
                  }, 100);  
              },
            });

      }


    } catch (error) {

      this.$error({
              title: '啊哦~',
              content: '获取数据失败'+error,
              okType: 'danger',
              okText: '请刷新重试',
              onOk() {                 
                  setTimeout(() => {
                    window.location.reload();
                  }, 100);  
              },
      });

    }
  },
  gopay(){ 
    switch(this.payData.PaymentType){
      case "1":

        this.payData.OrderId=this.orderData.OrderId
        this.payData.PaymentType="1"
        this.paybtnLoading=true
        this.aliPay()
        break;
      case "3":
        this.payData.PaymentType="3"
        this.payData.OrderId=this.$route.query.OrderId
        this.aliPay()
        break;
      case "4":
        this.payData.PaymentType="4"
        this.payData.OrderId=this.$route.query.OrderId
        console.log(2)
        this.goWeChatPay()
        break; 
    }
  },
  aliPay() {

    
    this.$axios.post('/payment', this.payData)
        .then(response => {
          if (response.data.RetCode === "Success") {

            const div = document.createElement('div');
            div.innerHTML = response.data.Content;

            // 获取 form 元素
            const form = div.querySelector('form');
            if (form) {
              // 设置 form 的 target 属性为 _blank，以便在新标签页中打开
              form.target = '_blank';

              // 设置表单的字符编码为 UTF-8
              form.acceptCharset = 'UTF-8';

              // 将表单添加到文档中
              document.body.appendChild(form);

              // 提交表单
              form.submit();
            }
            //console.log(form);

          } else {
            console.log(response.data.RetMessage);
          }
        })
        .catch(error => {
          console.error("Error fetching hotel rates:", error);
          
        });


  },
  goWeChatPay() {
    this.$axios.post('/payment', this.payData)
        .then(response => {
          if (response.data.RetCode === "Success") {

            console.log(response);
   
            /* 存入返回的二维码 10分钟 */  
            var expiresAt = new Date(new Date() * 1 + 60*10 * 1000)  
            var content={
              "content": response.data.Content,
              "expiresAt":expiresAt
            }
            Cookies.set("etxing_Content_" + this.payData.OrderId, JSON.stringify(content), { expires:  expiresAt });

            router.push({
                      name: 'OrderWechatPay',
                      query: {PayStatus:response.PayStatus,out_trade_no:this.payData.OrderId,HotelId:this.hotelId}
                  });

          } else {
            console.log(response);
            alert(response.data.RetMessage + "- 请重新预订！"); // location.replace(document.referrer)
            if (response.data.RetMessage == "订单已失效") {
              location.href = "manage.html?orderNumber=".concat(this.payData.OrderId);
            }

          } 
        })
        .catch(error => {
          console.error("Error fetching hotel rates:", error);
          
        });
  }


}
}
</script>
<style scoped>

.dateStyle{border: 1px dotted #ddd; padding: 5px 10px;}
.room_addition span {

    font-size: 12px;
    padding: 0px 5px
}

</style>
