<template>

    <a-menu
      v-model:openKeys="openKeys"
      v-model:selectedKeys="selectedKeys"
      mode="inline"

      :inline-collapsed="collapsed"
      :items="items"
      @select="menuSelect"
    ></a-menu>
 
</template>

<script>
import { h } from 'vue';
import {
  ShopFilled,
  IdcardFilled,
  AccountBookFilled,

  NotificationFilled,

} from '@ant-design/icons-vue';

export default {
  data() {
    return {
      collapsed: false,
      selectedKeys: ['/order/list'],
      openKeys: ['/order/'],
      items: [
        {
          key: '/order/',
          icon: () => h(ShopFilled),
          label: '订单管理',
          title: '订单管理',
          children: [
            {
              key: '/order/list',
              label: '酒店订单',
              title: '酒店订单',
            },
            {
              key: '/order/tour/list',
              label: '旅游订单',
              title: '旅游订单',
            }
          ],
        },
        {
          key: 'billing',
          icon: () => h(AccountBookFilled),
          label: '账单管理',
          title: '账单管理',
          children: [
            {
              key: 'billing/list',
              label: '酒店账单明细',
              title: '酒店账单明细',
            },
            {
              key: 'billing/tour/list',
              label: '旅游账单明细',
              title: '旅游账单明细',
            }
          ],
        },

        {
          key: '/account/',
          icon: () => h(IdcardFilled),
          label: '个人中心',
          title: '个人中心',
          children: [
            {
              key: '/account/info',
              label: '企业信息',
              title: '企业信息',
            },
            {
              key: '/account/list',
              label: '账号管理',
              title: '账号管理',
            }
          ],
        },
        {
          key: '/account/notice',
          icon: () => h(NotificationFilled),
          label: '系统通知',
          title: '系统通知',
        },
      ],
    };
  },
  watch: {
  },
  created() {
    // 监听窗口大小变化，根据窗口宽度动态设置侧边栏折叠状态
   //console.log(this.$route.matched.map(item=>item.path)[0])
   this.openKeys=this.$route.matched.map(item=>item.path)
   this.selectedKeys=this.$route.matched.map(item=>item.path);
  },
  methods: {

    menuSelect({ key }) {
      //console.log(key);
      // 点击菜单时跳转到对应的路由
      this.$router.push({ path: key });
    },
  },
};
</script>
